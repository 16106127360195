@import "./mixins.scss";
@import "./variable.scss";

.top-margin {
    margin-top: 15px
}

// action cards wrapping
@media screen and (max-width: 992px) {
    .action-column {
        flex: 100% !important; /* !important so that it overrides the inline style because by default it has higher priority */
    }
}


// Get arrows for carousel
.anticon.anticon-left.slick-arrow.slick-prev {
    display: block;
    color: black;
    background: transparent;
    font-size: 35px;
    left: -48px;
    z-index: 9999999999;
}
.anticon.anticon-right.slick-arrow.slick-next {
    display: block;
    color: black;
    background: transparent;
    font-size: 35px;
    right: -40px;
    z-index: 9999999999;
}
// End Get arrows for carousel

.ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color .3s;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: #000000d9;
    font-size: 16px;
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
    color: #00000073;
    font-size: 14px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: var(--ant-primary-5);
    font-size: 48px;
}
.selected-image {
    object-fit: contain;
    display: block
}

#root {
    height: 100%;
}

html {
    font-size: 62.5%;
    font-family: "Roboto", sans-serif;
}

h1,
p,
span,
label {
    margin: 0;
    font-family: "Roboto", sans-serif;
}

body {
    margin: 0;
    font-weight: 400;
    font-size: 1.6rem;
    font-family: "Roboto", sans-serif;
    background-color: rgba(246, 246, 247, 1);
}

.feature-container {
    max-width: 148rem;
}

.pro-menu-card {
    > .ant-card-head {
        padding: 0 2.4rem 0 0;
        .ant-card-head-title,
        .ant-card-extra {
            padding: 0;
            .ant-menu {
                border: 0;
                margin-bottom: 0.1rem;
                .ant-menu-item {
                    padding: 1rem 1.6rem;
                    margin-right: 1.6rem;
                    &:after {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}

.w-full {
    width: 100% !important;
}

@mixin text-base {
    &.border {
        border-bottom: 1px solid;
    }
    &.italic {
        font-style: italic;
    }
    &.light {
        color: #78909c;
    }
    &.danger {
        color: #ff4d4f;
    }
    &.success {
        color: #87d068;
    }
    &.semi-bold {
        font-weight: 500;
    }
    &.bold {
        font-weight: 700;
    }
    &.normal {
        font-weight: 400;
    }
}

a,
.text-link {
    color: #202323;
    cursor: pointer;
    &:hover {
        color: $PRIMARY_COLOR !important;
    }
    &.no-border:not(:hover) {
        border: none;
    }
    &.reverse {
        color: $PRIMARY_COLOR;
    }
    @include text-base;
}

.text-small {
    line-height: 1.2;
    font-size: 1.2rem;
    @include text-base;
}

.text-xl {
    line-height: 1.6;
    font-size: 1.4rem;
    @include text-base;
}

.text-2xl {
    line-height: 1.6;
    font-size: 1.6rem;
    @include text-base;
}

.text-3xl {
    line-height: 1.6;
    font-size: 1.8rem;
    @include text-base;
}

.text-4xl {
    line-height: 1.6;
    font-size: 2.5rem;
    @include text-base;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.cursor-pointer {
    cursor: pointer;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.block {
    display: block;
}

.inline {
    display: inline;
}

.hidden {
    display: none;
}

.table {
    display: table;
}

.justify-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ant-form-item {
    margin-bottom: 1.6rem;
    &.no-margin {
        margin-bottom: 0;
    }
}

.ant-card.no-padding {
    > .ant-card-body {
        padding: 0;
    }
}

.ant-modal.no-padding {
    .ant-modal-body {
        padding: 0;
    }
}

.pro-card-table {
    .ant-card-body {
        padding: 0;
        .ant-card-loading-content {
            padding: 1.6rem 2.4rem;
        }
    }
}

.ant-table-thead > tr > th {
    border-top: 1px solid rgb(225, 227, 229);
    border-bottom: 1px solid rgb(225, 227, 229);
}

.ant-table-pagination.ant-pagination {
    margin: 1.6rem !important;
}

.ant-card {
    .ant-card-head {
        margin-bottom: 0;
    }
    .ant-card-body {
        .ant-menu-horizontal {
            border-bottom: 0;
        }
    }
    &.ant-card-bordered {
        border-color: rgb(225, 227, 229);
    }
    .ant-card-loading-content {
        padding: 1.6rem 2.4rem;
    }
    .ant-table-thead > tr > th {
        border-top: 0;
    }
    .ant-table-thead > tr > th:not(.ant-table-selection-column),
    .ant-table-tbody > tr > td:not(.ant-table-selection-column) {
        &:first-child {
            padding-left: 2.4rem;
        }
        &:last-child {
            padding-right: 2.4rem;
        }
    }
}

.ant-page-header {
    padding-top: 0 !important;
    padding-left: 0 !important;
    margin-top: -1.2rem !important;
    .ant-page-header-heading {
        align-items: center;
    }
}

@include list-loop(".pt-", "padding-top");
@include list-loop(".mt-", "margin-top");
@include list-loop(".pb-", "padding-bottom");
@include list-loop(".mb-", "margin-bottom");

.app-layout {
    min-height: 100%;
    .ant-layout-header {
        width: 100%;
        height: auto;
        line-height: 1.6;
        padding: 1rem 1.6rem;
        background-color: #ffffff;
        border-bottom: 1px solid rgb(225, 227, 229);
        .header-menu {
            cursor: pointer;
            min-width: 3.6rem;
            border-radius: 3px;
            padding: 0.8rem 1.2rem;
            transition: background-color 0.1s;
            &:hover {
                transition: background-color 0.1s;
                background-color: rgb(246, 246, 247);
            }
        }
    }
    .ant-layout {
        background: rgba(246, 246, 247, 1);
        .ant-layout-content {
            padding: 3rem;
        }
    }
}

.login-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(114, 120, 204);
    .ant-card {
        width: 36rem;
    }
}

.auction-item-list {
    .ant-card-head-wrapper {
        align-items: flex-start;
        .ant-col,
        .ant-form-item-label > label {
            font-weight: 400;
        }
    }
}

.auction-item-edit {
    .components-card {
        padding: 2rem;
        margin: 2rem 0;
        border-radius: 6px;
        position: relative;
        border: 2px solid $PRIMARY_COLOR;
        background-color: rgb(251, 251, 253);
        .delete-trigger {
            top: 1.2rem;
            right: 2.2rem;
            font-size: 2rem;
            position: absolute;
        }
        .collapse-trigger {
            top: 1.2rem;
            right: 5.2rem;
            font-size: 2rem;
            cursor: pointer;
            position: absolute;
        }
        .attribute-col {
            &.hidden {
                display: none;
            }
        }
    }
    .images-col {
        top: 0;
        right: 0rem;
        width: 38rem;
        position: absolute;
        .selected-image {
            height: 25rem;
            object-fit: contain;
            margin-bottom: 2.4rem;
        }
        .ant-space {
            display: flex;
            margin-bottom: 1.6rem;
            .image-item {
                width: 8rem;
                height: 8rem;
                cursor: pointer;
                object-fit: contain;
                &.selected {
                    border: 2px solid $PRIMARY_COLOR;
                }
            }
        }
        .user-upload {
            display: table;
        }
    }
}

.auction-item-add-attr-modal {
    .picklist-option-item {
        width: 100%;
        .ant-space-item:first-child {
            flex: 1;
        }
    }
}

.attr-value-dropdown {
    .ant-divider {
        margin: 1.2rem 0;
    }
    .add-attr-value {
        display: flex;
        align-items: center;
        padding: 0 1.2rem 1.2rem;
        input {
            flex: 1;
            margin-right: 1.2rem;
        }
    }
}

.customer-assignment-modal {
    .ant-card {
        .ant-table {
            .ant-table-expanded-row {
                > td {
                    padding: 1.6rem !important;
                    .ant-table {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.customer-collection-card {
    .ant-table {
        .ant-table-expanded-row {
            > td {
                padding: 1.6rem !important;
                .ant-table {
                    margin: 0 !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .app-layout {
        .ant-layout-sider {
            flex: 6rem !important;
            width: 6rem !important;
            min-width: 6rem !important;
            max-width: 6rem !important;
            .ant-menu-vertical .ant-menu-item {
                padding: 0 1rem;
                text-align: center;
            }
            .ant-menu-title-content {
                display: none !important;
            }
        }
        .anticon-menu-fold {
            display: none;
        }
        .ant-layout {
            .ant-layout-content {
                padding: 1rem 1.6rem;
            }
            .ant-card {
                .ant-card-head {
                    padding: 0 1.6rem;
                }
            }
        }
    }
    .ant-form {
        .ant-row {
            flex-wrap: wrap;
            .ant-col {
                max-width: 100%;
                flex: 100% !important;
            }
        }
    }
    .ant-modal {
        top: 2rem !important;
        .ant-modal-content {
            .ant-modal-header {
                padding: 1rem 1.6rem;
            }
            .ant-modal-close {
                top: -0.4rem;
            }
            .ant-modal-body {
                padding: 1rem 1.6rem;
            }
        }
    }
    .auction-item-edit {
        .images-col {
            width: auto;
            position: unset;
        }
    }
    .jewelry-filter-component {
        flex-wrap: wrap;
        > .ant-col:not(:last-child) {
            margin-bottom: 1.2rem;
        }
    }
    .jewelry-range-filter {
        .ant-row {
            flex-flow: row wrap;
            .ant-col {
                flex: 50% !important;
                &:last-child {
                    text-align: right;
                }
            }
        }
    }
    .auction-management {
        .ant-card-head {
            .ant-card-extra {
                .ant-space {
                    flex-flow: row wrap;
                }
            }
        }
    }
}
